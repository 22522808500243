import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, DropdownButton, Dropdown, Navbar, Nav } from 'react-bootstrap';
import logoPath from '../images/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { signoutUser } from '../actions/authActions';
import { openModal } from '../actions/modalActions';
import { can } from '../services/ability';
import { useNavigate } from 'react-router-dom';
import { useMobileMediaQuery } from "../hooks/responsiveMediaQueries";
import ModalManager from './modals/ModalManager';

import '../css/Header.css';

function Header({...props}) {
  const isMobile = useMobileMediaQuery();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authData);
  const currentUser = authData.currentUser;
  const useFixedTopHeader = props.useFixedTopHeader || false;
  const useLightButtons = props.useLightButtons || false;

  function MyApplicationsDropdownItem() {
    const navigate = useNavigate();

    function handleMyApplicationsClick() {
      navigate(`/my_applications`);
    }

    return (
      <Dropdown.Item onClick={handleMyApplicationsClick}>My Applications</Dropdown.Item>
    )
  };

  function InstantApplyDefaults() {
    const navigate = useNavigate();

    function handleInstantApplyDefaultsClick() {
      navigate(`/instant_apply_defaults`);
    }

    return (
      <Dropdown.Item onClick={handleInstantApplyDefaultsClick}>Instant Apply Defaults</Dropdown.Item>
    )
  };

  function MyShareLinksDropdownItem() {
    const navigate = useNavigate();

    function handleMyShareLinksClick() {
      navigate(`/my_share_links`);
    }

    return (
      <Dropdown.Item onClick={handleMyShareLinksClick}>My Share Links</Dropdown.Item>
    )
  };

  function handleLogoutClick() {
    dispatch(signoutUser());
  };

  function handleSignUpClick() {
    dispatch(openModal('SignUpModal'));
  };

  function handleLoginClick() {
    dispatch(openModal('LoginModal', {}));
  };

  function getHeaderClassName() {
    let className = "";

    if (useFixedTopHeader && !isMobile) {
      className += " fixed-top-header"
    }

    if (useLightButtons && !isMobile) {
      className += " use-light-buttons";
    }

    return className;
  }

  function getNavBtnClassName() {
    let className = "";

    if(!isMobile) {
      className = useLightButtons ? "light-btn" : "standard-btn";
    }

    return className;
  }

  return (
    <>
      <header className={getHeaderClassName()}>
        <Navbar fixed={useFixedTopHeader && !isMobile ? "top" : "none"} variant="light" expand="md">
          <Container fluid className="justify-content-between ms-0 ms-md-2 gx-0 gx-md-4">
            <Navbar.Toggle aria-controls="collapsable-navbar" className="order-1 order-md-2"/>
            <Navbar.Brand href="/home" className="order-2 order-md-1">
              <img alt="magnetworks" className="brand" src={logoPath}/>
            </Navbar.Brand>
            <div className="d-flex flex-row order-3 order-md-3">
              <DropdownButton align="end" id="header-user-dropdown" className="ms-md-2 header-user-dropdown" title={<i className="fa fa-2x fa-user-circle"/>}>
                {currentUser ?
                  <>
                  <Dropdown.ItemText>Signed in as </Dropdown.ItemText>
                  <Dropdown.ItemText>{currentUser.email}</Dropdown.ItemText>
                  <Dropdown.Divider />
                  <Dropdown.Item as={Link} to='/me'>User Settings</Dropdown.Item>
                  <Dropdown.Divider />
                  {can(authData, 'read', 'employers_dashboard') && <Dropdown.Item href='/employers/dashboard'>Dashboard</Dropdown.Item> }
                  {can(authData, 'manage', 'basic') ?
                    <>
                        { can(authData, 'read', 'Application') && <MyApplicationsDropdownItem /> }
                        { can(authData, 'read', 'DefaultApplication') && <InstantApplyDefaults /> }
                      <MyShareLinksDropdownItem/>
                    </>
                  : null}
                  {can(authData, 'read', 'affiliate_links') && <Dropdown.Item href='/admin/affiliate_links'>Affiliate Links</Dropdown.Item>}
                  {can(authData, 'manage', 'organizations') && <Dropdown.Item href='/admin/organizations'>Organizations</Dropdown.Item>}
                  <Dropdown.Item onClick={handleLogoutClick}>Log out</Dropdown.Item>
                  </>
                :
                  <>
                  <Dropdown.Item onClick={handleLoginClick}>Log in</Dropdown.Item>
                  <Dropdown.Item onClick={handleSignUpClick} >Sign up</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="/employers">Employers - Post a job</Dropdown.Item>
                  </>
                }
              </DropdownButton>

            </div>
            <Navbar.Collapse id="collapsable-navbar" className="order-3 order-md-2">
              <Nav className="me-auto"></Nav>
              <Nav>
                <Nav.Link className={"btn nav-btn mx-0 mx-md-2 " + (getNavBtnClassName())} href="/jobs">Jobs</Nav.Link>
                {
                  (authData.tokenChecked === true) && (!currentUser || can(authData, 'create', 'Job')) ?
                    <Nav.Link className={"btn nav-btn " + (getNavBtnClassName())} href={authData.loggedIn ? "/employers/my_business/jobs/add" : "/employers"}>Post a Job</Nav.Link>
                  : ""
                }
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export default Header;