import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import OrganizationNotFound from './OrganizationNotFound';
import OrganizationsPublicProfileHeader from './OrganizationsPublicProfileHeader';
import Loading from '../../Loading';

function OrganizationsPublicLayout({ component: Component, ...props }) {
  const { organization_id } = useParams();
  const [organization, setOrganization] = useState(null);
  const [isLoadingOrganization, setIsLoadingOrganization] = useState(true);

  useEffect(() => {
    if (organization_id && organization === null) {
      fetchOrganization();
    }
  }, [organization_id, organization]);

  function fetchOrganization() {
    axios.get(`/api/v1/organizations/${organization_id}/public_profile`)
      .then((response) => {
        setOrganization(response.data);
      })
      .catch((error) => {
        // Do nothing
      })
      .finally(() => {
        setIsLoadingOrganization(false);
      });
  }

  return (
    <>
      {isLoadingOrganization ?
        <Loading />
        : organization ?
          <>
            <OrganizationsPublicProfileHeader organization={organization} />
            <Component organizationId={organization.id} businessIds={organization.business_ids} />
          </>
          :
          <OrganizationNotFound />
      }
    </>
  );
}

export default OrganizationsPublicLayout;