import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Col, Row, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import DownloadFileLink from "./DownloadFileLink";

import { addGlobalAlert } from "../actions/globalAlertActions";
import Loading from "./Loading.js";
import PhoneInput from "../components/inputs/PhoneInput.js";
import { createApplication } from "../api/applications.js";
import useGetApi from '../hooks/useApiGet';
import { FetchFile } from "../utils/FetchFileUtils";

function MwApply({ organizationId, businessId }) {
  const resumeFileHiddenInput = useRef(null);
  const coverLetterFileHiddenInput = useRef(null);

  const {job_id} = useParams();
  const [formData, setFormData] = useState({first_name: "", last_name: "", email: "", phone: ""});
  const [errorMessages, setErrorMessages] = useState({});
  const [job, setJob] = useState(null);
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {data, loading} = useGetApi({url: '/api/v1/me/default_application'});

  useEffect(() => {
    if (!loading) {
      if(data) {
        setFormData(data);
        fileSetup();
        setisLoading(false);
      }
    }

    if(job_id){
      axios.get(`/api/v1/me/eligible_to_apply_to_job?job_id=${job_id}`)
      .then(
        (response) => {
          let eligible_to_apply = response.data.result;
          fetchJob(job_id, eligible_to_apply);
        }
      );
    }
  }, [job_id, loading]);

  async function fileSetup() {
    if(data.resume_url) {
      const response = await FetchFile({ url: data.resume_url , fileName: data.resume_filename , type: "application/pdf" });
      if(response.valid) { setResume(response.fetchFile) }
    }
    if(data.cover_letter_url) {
      const response = await FetchFile({ url: data.cover_letter_url , fileName: data.cover_letter_filename , type: "application/pdf" })
      if(response.valid) { setCoverLetter(response.fetchFile) }
    }
  }

  function fetchJob(job_id, eligible_to_apply) {
    setisLoading(true);

    axios.get(`/api/v1/jobs/${job_id}`)
    .then(
      (response) => {
        if (eligible_to_apply) {
          setJob(response.data);
          setisLoading(false);
        }
        else {
          dispatch(addGlobalAlert({message: "You have already applied to this job.", alertType: "danger", removeOnPageChange: false}));
          navigate('/jobs')
        }
      },
      (error) => {
        setJob(null);
        setisLoading(false);
      }
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    setisLoading(true);
    setErrorMessages({});

    const finalFormData = new FormData(event.target);

    if(resume)
      finalFormData.append("application[resume]", resume);

    if(coverLetter)
      finalFormData.append("application[cover_letter]", coverLetter);

    for (const k in formData) {
      finalFormData.append(`application[${k}]`, formData[k] ?? "" );
    }

    const requestOptions = {headers: {'Content-Type': 'multipart/form-data'}}

    createApplication({jobId: job_id, data: finalFormData, requestOptions: requestOptions})
      .then(
        (response) => {
          dispatch(addGlobalAlert({message: "Application submitted successfully!", alertType: "success", removeOnPageChange: false}));
          businessId ? navigate(`/employers/${businessId}/jobs`)
          : organizationId ? navigate(`/employers/${job.business?.id}/jobs`)
          : navigate("/jobs");
        },
        (error) => {
          window.scrollTo(0, 0);
          const errorMessage = error.response.data?.error?.job ? error.response.data?.error?.job : "Failed to submit application. Please correct the issues below.";
          dispatch(addGlobalAlert({message: errorMessage, alertType: "danger"}));

          if(error.response.data && error.response.data.error) {
            const messages = {};

            for(const key in error.response.data.error) {
              messages[key] = error.response.data.error[key][0];
            }

            setErrorMessages(messages);
          }
        }
      );

    setisLoading(false);
  };

  return (
    <>
    {isLoading ?
      <Loading/>
    :
      <Container>
        {!isLoading && (job !== null) ?
          <Row className="justify-content-center">
            <Col lg={7} md={12} sm={12}>
              <h2 className="mt-3">{job?.title}</h2>
              {businessId ? <></> : <h5 className="mb-3">{job?.business?.name}</h5>}

              <Form onSubmit={!isLoading ? (e) => handleSubmit(e) : null}>

                <Row className="mb-3">
                  <Form.Group controlId="first-name" as={Col} sm={12} md={6}>
                    <Form.Label className="required">First name</Form.Label>
                    <Form.Control type="text" value={formData?.first_name ?? ""} onChange={e => setFormData({...formData, first_name: e.target.value})}/>
                    <span className="text-danger">{errorMessages["first_name"]}</span>
                  </Form.Group>
                  <Form.Group controlId="last-name" as={Col} sm={12} md={6}>
                    <Form.Label className="required">Last name</Form.Label>
                    <Form.Control type="text" value={formData?.last_name ?? ""} onChange={e => setFormData({...formData, last_name: e.target.value})}/>
                    <span className="text-danger">{errorMessages["last_name"]}</span>
                  </Form.Group>
                </Row>

                <Form.Group controlId="email" className="mb-3">
                  <Form.Label className="required">Email</Form.Label>
                  <Form.Control type="email" value={formData?.email ?? ""} onChange={e => setFormData({...formData, email: e.target.value})}/>
                  <span className="text-danger">{errorMessages["email"]}</span>
                </Form.Group>

                <Form.Group controlId="phone" className="mb-3">
                  <Form.Label className="required">Phone</Form.Label>
                  <PhoneInput name="phoneNumber" value={formData?.phone ?? ""} onChange={e => setFormData({ ...formData, phone: e.target.value.replace(/\D/g, '') })} />
                  <span className="text-danger">{errorMessages["phone"]}</span>
                </Form.Group>

                <Form.Group controlId="resume" className="mb-3">
                  <Form.Label className={job.require_resume ? "required" : ""}>Resume</Form.Label>
                  {formData.resume_url ? <span className="text-center ms-2"><DownloadFileLink fileUrl={formData?.resume_url} fileName={formData?.resume_filename}></DownloadFileLink></span> : ""}
                  <InputGroup className="mb-3 input-file-hover" onClick={() => resumeFileHiddenInput.current.click()}>
                    <InputGroup.Text>
                      Choose file
                    </InputGroup.Text>
                    <input type="file" className="d-none" ref={resumeFileHiddenInput} onChange={(e) => setResume(e.target.files[0])} />
                    <Form.Control type="text" readOnly className="file-name-field file-field-background" value={resume?.name || formData?.resume_filename || "No file chosen"} />
                  </InputGroup>
                  <span className="text-danger">{errorMessages["resume"]}</span>
                </Form.Group>

                <Form.Group controlId="cover_leter" className="mb-3">
                  <Form.Label className={job.require_cover_letter ? "required" : ""}>Cover letter</Form.Label>
                  {formData.cover_letter_url ? <span className="text-center ms-2"><DownloadFileLink fileUrl={formData?.cover_letter_url} fileName={formData?.cover_letter_filename}></DownloadFileLink></span> : ""}
                  <InputGroup className="mb-3 input-file-hover" onClick={() => coverLetterFileHiddenInput.current.click()}>
                    <InputGroup.Text>
                      Choose file
                    </InputGroup.Text>
                    <input type="file" className="d-none" ref={coverLetterFileHiddenInput} onChange={(e) => setCoverLetter(e.target.files[0])} />
                    <Form.Control type="text" readOnly className="file-name-field file-field-background" value={coverLetter?.name || formData?.cover_letter_filename || "No file chosen"} />
                  </InputGroup>
                  <span className="text-danger">{errorMessages["cover_letter"]}</span>
                </Form.Group>

                <Form.Group controlId="comments" className="mb-3">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control as="textarea" rows={5} placeholder="Add anything else you'd like to share." onChange={e => setFormData({...formData, comments: e.target.value})}/>
                  <span className="text-danger">{errorMessages["comments"]}</span>
                </Form.Group>

                <Row>
                  <Col md={3} className="mt-3">
                    <Button className="standard-btn w-100" type="submit" disabled={isLoading}>
                      Apply
                    </Button>
                  </Col>
                </Row>

              </Form>
            </Col>
          </Row>
        :
        <Row className="justify-content-center">
          <Col xs={8} md={4} className="mt-4">
            <Alert className="text-center" variant={'secondary'}>
              <div>We are sorry.</div>
              <div>That job doesn't seem to exist.</div>
            </Alert>
          </Col>
        </Row>
        }
      </Container>
    }
    </>
  );
}

export default MwApply;