import { Form } from 'react-bootstrap';
import { useEffect, useRef } from "react";
import { Link } from 'react-router-dom';

import BannerPlaceholderPath from '../../images/banner-placeholder-850x150.png';
import LogoPlaceholderPath from '../../images/logo-placeholder-500x500.png';
import '../../css/inputs/ImageUploadInput.css';

function ImageUploadInput({ label = '', ctaText = 'Change', hidden, errorMessage = '', hintText = '', type = '', currentImage, value, ...props }) {
  const imageInputRef = useRef(null);

  function uploadClicked() {
    imageInputRef.current?.click();
  };

  useEffect(() => {
    imageInputRef.current.value = '';
  }, [currentImage, value]);

  function getPreviewImgSrc() {
    let previewSrc = '';

    switch (type) {
      case 'logo':
        previewSrc = LogoPlaceholderPath;
        break;
      case 'banner':
        previewSrc = BannerPlaceholderPath;
        break;
      default:
        previewSrc = LogoPlaceholderPath;
    }

    if(value) {
      previewSrc = URL.createObjectURL(value);
    } else if (currentImage) {
      previewSrc = currentImage;
    }

    return previewSrc;
  }

  return (
    <div className="image-upload-input d-flex flex-column">
      <Form.Control ref={imageInputRef} type="file" accept=".png, .jpg, .jpeg" className="d-none hidden" onChange={props.onChange} {...props} />
      { hidden ?
        <img className={"preview " + type} alt={type + "-preview"} src={getPreviewImgSrc()} />
      :
        <>
          <img onClick={uploadClicked} className={"active preview " + type} alt={type + "-preview"} src={getPreviewImgSrc()} />
          <div>
            <small><Link to="#" onClick={uploadClicked}>{ctaText}</Link></small>
            {hintText && <small className="text-muted">{hintText}</small>}
          </div>
          <Form.Control.Feedback type="invalid">
            {errorMessage}
          </Form.Control.Feedback>
        </>
      }
    </div>
  );
}

export default ImageUploadInput;