import { Container, Row, Col } from 'react-bootstrap';

import DefaultBannerPath from '../../../images/default-banner.png';
import '../../../css/organizations/OrganizationsPublicProfileHeader.css';

function OrganizationsPublicProfileHeader({ organization }) {
  function getDynamicBannerStyle() {
    const backgroundImage = organization.banner ? organization.banner : DefaultBannerPath;
    return { backgroundImage: "url(" + backgroundImage + ")" };
  }

  return (
    <Container className="organization-profile-header">
      <Row className="justify-content-center">
        <Col xs={12} lg={10} xl={9} xxl={8} className="d-flex g-0 mt-md-2">
          <div style={getDynamicBannerStyle()} className="banner"></div>
        </Col>
        <Col xs={12} lg={10} xl={9} xxl={8}>
          <Row>
            <Col xs={6}>
              {organization.logo &&
                <div className="ms-3 logo-container">
                  <img className="logo" src={organization.logo} alt="organization-logo" />
                </div>
              }
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="mt-2 mx-3">
                <h5>{organization.name}</h5>
                {organization.tagline && <div>{organization.tagline}</div>}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default OrganizationsPublicProfileHeader;