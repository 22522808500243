import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Table, Row, Col, Breadcrumb, Button, OverlayTrigger, Tooltip, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import Loading from '../Loading';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

const AffiliateLinkDetails = () => {
  const params = useParams();
  const [affiliateLinkData, setAffiliateLinkData] = useState(null);
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [filters, setfilters] = useState({selection: null});
  const [errors, setErrors] = useState({});

  const tooltip = (
    <Tooltip>Copied!</Tooltip>
  );

  useEffect(() => {
    axios.get(`/api/v1/affiliate_links/${params.id}`)
      .then((response) => {
        setAffiliateLinkData(response.data);
        setfilters({selection: 'all'});
      })
      .catch((error) => {
        setErrors({...errors, linkError: 'Error loading affiliate link.'});
      });
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [filters]);

  const handleActivityChange = (event) => {
    setPage(1);
    setfilters({ ...filters, selection: event.target.value });
  }

  const fetchNext = () => {
    setPage((current) => {
      const pageParam = current + 1;
      fetchEvents({ page: pageParam, append: true })

      return pageParam;
    });
  }

  const fetchEvents = (options) => {
    const pageParam = options?.page !== undefined ? options.page : page;
    let url = `/api/v1/affiliate_links/${params.id}/events?page=${pageParam}`;

    switch (filters.selection) {
      case 'attributed':
        url += '&selection=attributed';
        break;
      case 'views':
        url += '&selection=views';
        break;
      case 'sign_ups':
        url += '&selection=sign_ups';
        break;
      default:
        break;
    }

    axios.get(url)
      .then((response) => {
        const newValue = options?.append === true ? [...events, ...response.data] : response.data;
        setEvents(newValue);
      })
      .catch((error) => {
        setErrors({...errors, eventsError: 'Error loading events.'});
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const hasMoreEvents = () => {
    switch (filters.selection) {
      case 'attributed':
        return (affiliateLinkData?.attribted_count > 0 && affiliateLinkData?.events.map(e => e.atttributed === true) > events.length);
      case 'views':
        return (affiliateLinkData?.view_count > 0 && affiliateLinkData?.view_count > events.length);
      case 'sign_ups':
        return (affiliateLinkData?.sign_up_count > 0 && affiliateLinkData?.sign_up_count > events.length);
      default:
        return (events.length > 0 && affiliateLinkData?.event_count > events.length);
    }
  }

  const eventType = (value) => {
    switch(value) {
      case 'AffiliateLinkEvent::View':
        return 'View';
      case 'AffiliateLinkEvent::SignUp':
        return 'Sign up';
      default:
        return 'View';
    }
  }

  const url = () => {
    return `${window.location.protocol}//${window.location.hostname}/l/${affiliateLinkData?.key}`;
  }

  function handleCopy(event) {
    navigator.clipboard.writeText(url());
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }

  return (
    <Container>
      <Row className='mt-3'>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin/affiliate_links' }}>Affiliate Links</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/admin/affiliate_links/${affiliateLinkData?.id}/edit` }}>
              {affiliateLinkData?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {errors.linkError ?
            <Row>
              <Col>
                <Alert variant="danger">{errors.linkError}</Alert>
              </Col>
            </Row>
          :
            <>
              <Row>
                <h2>{affiliateLinkData?.name}</h2>
                <Col>
                  <div>Key: {affiliateLinkData?.key}</div>
                  <div>
                    URL: <a href={url()} style={{ fontWeight: '500' }}>{url()}</a>
                    <OverlayTrigger placement="top" overlay={isCopied ? tooltip : <></>}>
                      <Button className="standard-btn ms-2 btn-sm" onClick={e => handleCopy(e)}>
                        {isCopied ?
                          <i className="fa fa-check"></i>
                          :
                          <i className="fa fa-copy" onClick={e => handleCopy(e)}></i>
                        }
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div>Sign up type: <span style={{ fontWeight: '500' }}>{affiliateLinkData?.sign_up_type}</span></div>
                  <div>Views: <span style={{ fontWeight: '500' }}>{affiliateLinkData?.view_count}</span></div>
                  <div>Sign ups: <span style={{ fontWeight: '500' }}>{affiliateLinkData?.sign_up_count}</span></div>
                </Col>
              </Row>

              <div className="mt-4">
                <span className="h5 d-inline">Activity</span>
                <Form.Select
                  className="ms-3 d-inline"
                  value={filters.selection}
                  onChange={handleActivityChange}
                  style={{ width: '12rem' }}
                >
                  <option value='all'>All</option>
                  <option value='views'>Views</option>
                  <option value='sign_ups'>Sign ups</option>
                  <option value='attributed'>Attributed sign ups</option>
                </Form.Select>
              </div>

              {errors.eventsError ?
                <Row className="mt-4">
                  <Col>
                    <Alert variant="danger">{errors.eventsError}</Alert>
                  </Col>
                </Row>
                :
                <>
                  {events.length === 0 ?
                    <Row className="mt-4">
                      <Col>
                        <Alert variant="warning">There is no activity for this link yet.</Alert>
                      </Col>
                    </Row>
                    :
                    <InfiniteScroll
                      dataLength={events.length}
                      next={fetchNext}
                      hasMore={hasMoreEvents()}
                      loader={
                        <center>
                          <span><i className="fa fa-spinner fa-spin me-2" />Loading more events...</span>
                        </center>
                      }
                    >
                      <Table striped>
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th>Signature</th>
                            <th>User</th>
                            <th>Timestamp</th>
                            <th className='text-center'>Attributed</th>
                          </tr>
                        </thead>
                        <tbody id="affiliate-link-events">
                          {events.map((event) => (
                            <tr key={event.id}>
                              <td>{eventType(event.type)}</td>
                              <td>{event.signature}</td>
                              <td>{event.user?.email ?? '-'}</td>
                              <td>{new Date(event.created_at).toLocaleString('en-US')}</td>
                              <td className='text-center'>{event.attributed === null ? '-' : (event.attributed ? 'True' : 'False')}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </InfiniteScroll>
                  }
                </>
              }
            </>
          }
        </>
      )}
    </Container>
  );
}

export default AffiliateLinkDetails;