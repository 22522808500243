import { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import { GetFeedbackMessageFor } from '../../utils/FormUtils';
import ImageUploadInput from '../inputs/ImageUploadInput';

const OrganizationForm = ({ organization }) => {
  const [users, setUsers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    tagline: '',
    admin_ids: [],
    business_ids: []
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setFormData({
      ...formData,
      name: organization?.name || '',
      tagline: organization?.tagline || '',
      admin_ids: organization?.admins?.map(admin => admin.id) || [],
      business_ids: organization?.businesses?.map(business => business.id) || []
    });

    axios.get('/api/v1/users')
      .then((response) => {
        setUsers(response.data);
      });

    axios.get('/api/v1/businesses/all')
      .then((response) => {
        setBusinesses(response.data);
      });

    setSelectedUsers(organization?.admins || []);
    setSelectedBusinesses(organization?.businesses || []);
  }, [organization]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});

    const theData = new FormData(e.target);
    for(const key in formData) {
      if (formData[key] instanceof Array) {
        formData[key].forEach((value) => { theData.append(`organization[${key}][]`, value) });
      }
      else {
        theData.append(`organization[${key}]`, formData[key]);
      }
    }

    const request = organization?.id ?
      axios.put(`/api/v1/organizations/${organization.id}`, theData) :
      axios.post(`/api/v1/organizations`, theData);

    request
      .then((response) => {
        navigate('/admin/organizations');
      })
      .catch((error) => {
        setErrors(error.response.data.errors);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={12}>
          <Form.Group className="mb-1 mt-3" controlId="name">
            <Form.Label className="required">Name</Form.Label>
            <Form.Control
              type="text"
              name="organization[name]"
              placeholder="Enter name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              isInvalid={errors['name']}
            />
            <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'name')}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Form.Group controlId="organization-banner" className="mb-3">
          <Form.Label>Banner</Form.Label>
          <Col sm={12}>
            <ImageUploadInput
              type='banner'
              onChange={(e) => setFormData({ ...formData, banner: e.target.files[0] })}
              value={formData.banner}
              currentImage={organization?.banner}
              isInvalid={errors['banner']}
              errorMessage={GetFeedbackMessageFor(errors, 'banner')}
            />
          </Col>
        </Form.Group>
      </Row>

      <Row>
        <Form.Group controlId="organization-logo" className="mb-3">
          <Form.Label>Logo</Form.Label>
          <Col sm={12}>
            <ImageUploadInput
              type='logo'
              onChange={(e) => setFormData({ ...formData, logo: e.target.files[0] })}
              value={formData.logo}
              currentImage={organization?.logo}
              isInvalid={errors['logo']}
              errorMessage={GetFeedbackMessageFor(errors, 'logo')}
            />
          </Col>
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mb-3" controlId="tagline">
          <Form.Label>Tagline</Form.Label>
          <Col sm={7}>
            <Form.Control
              as="textarea"
              rows="1"
              value={formData.tagline}
              isInvalid={errors.tagline}
              onChange={e => setFormData({ ...formData, tagline: e.target.value })}
            />
            <Form.Control.Feedback type="invalid">
              {GetFeedbackMessageFor(errors, 'tagline')}
            </Form.Control.Feedback>
            <small className="text-muted">Maximum 140 characters</small>
          </Col>
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mb-1 mt-2" controlId="users">
          <Form.Label>Org admins</Form.Label>
          <Typeahead
            name="organization[admins][]"
            className={errors.admins ? "is-invalid" : ""}
            isInvalid={errors.admins !== undefined}
            multiple
            id="user-select"
            selected={selectedUsers}
            labelKey="display_value"
            onChange={(userValues) => {
              setSelectedUsers(userValues);
              setFormData({ ...formData, admin_ids: userValues.map(user => user.id) });
            }}
            options={users}
            placeholder="Assign org admins..." />
          <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'admins')}</Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row>
        <Form.Group className="mb-1 mt-3" controlId="businesses">
          <Form.Label>Businesses</Form.Label>
          <Typeahead
            name="organization[businesses][]"
            className={errors.businesses ? "is-invalid" : ""}
            isInvalid={errors.businesses !== undefined}
            multiple
            id="business-select"
            selected={selectedBusinesses}
            labelKey="name"
            onChange={(businessValues) => {
              setSelectedBusinesses(businessValues);
              setFormData({ ...formData, business_ids: businessValues.map(business => business.id) });
            }}
            options={businesses}
            placeholder="Assign businesses..." />
          <Form.Control.Feedback type="invalid">{GetFeedbackMessageFor(errors, 'businesses')}</Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row>
        <Col className='mt-4'>
          <Button style={{ width: '100px' }} type="submit" className="standard-btn" disabled={isSubmitting}>
            {isSubmitting ? <Spinner size="sm" /> : 'Save'}
          </Button>
          <Button style={{ width: '100px' }} type="button" as={Link} to={'/admin/organizations'} className="ms-2 light-btn">Cancel</Button>
        </Col>
      </Row>
    </Form>
  );
}

export default OrganizationForm;