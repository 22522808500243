import { Container, Col, Row, Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import OrganizationForm from './OrganizationForm';

const NewOrganization = () => {
  return (
    <Container>
      <Col className="my-3">
        <Row>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin/organizations' }}>Organizations</Breadcrumb.Item>
              <Breadcrumb.Item active>New</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col sm={12}>
            <OrganizationForm />
          </Col>
        </Row>
      </Col>
    </Container>
  );
}

export default NewOrganization;