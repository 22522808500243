import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const OrganizationTableEntry = ({ organization }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyJobFeedUrl = (e) => {
    navigator.clipboard.writeText(jobFeedUrl());
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  }

  const jobFeedUrl = () => {
    return `${window.location.protocol}//${window.location.hostname}${process.env.NODE_ENV === 'development' ? ':3000' : ''}/organizations/${organization.id}/jobs`;
  }

  return (
    <tr>
      <td>{organization.id}</td>
      <td>{organization.name}</td>
      <td className="text-center">{organization.admin_count}</td>
      <td className="text-center">{organization.business_count}</td>
      <td className="text-end">
        <OverlayTrigger placement={'top'} overlay={<Tooltip>{'Go to job feed'}</Tooltip>}>
          <Button variant="secondary" className="me-1" as={Link} to={jobFeedUrl()} target={'_blank'}>
            <i className="fa fa-xs fa-link"></i>
          </Button>
        </OverlayTrigger>

        <OverlayTrigger placement={'top'} overlay={<Tooltip>{'Copy job feed URL'}</Tooltip>}>
          <Button variant="secondary" className="me-1" onClick={handleCopyJobFeedUrl}>
            {isCopied ?
              <i className="fa fa-check"></i>
              :
              <i className="fa fa-copy"></i>
            }
          </Button>
        </OverlayTrigger>

        <Button variant="secondary" as={Link} to={`/admin/organizations/${organization.id}/edit`}>
          <i className="fa fa-edit" />
        </Button>
      </td>
    </tr>
  );
}

export default OrganizationTableEntry;