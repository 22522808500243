
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';
import GlobalAlert from '../components/GlobalAlert';
import { useSearchParams } from 'react-router-dom';

function RouteWithoutSidebar({ withContainer, ...props }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const frame = searchParams.get('frame');

  function getGlobalAlert() {
    return props.useFixedTopHeader ? (<div className="fixed-top"><GlobalAlert /></div>) : <GlobalAlert />;
  }

  return (
    <>
      { withContainer ?
        <>
          {frame === null && <Header {...props} />}
          <Container fluid>
            <Row>
              <Col>
                {getGlobalAlert()}
                <Row>
                  <Outlet />
                </Row>
              </Col>
            </Row>
          </Container>
          {frame === null && <Footer />}
        </>
      :
        <>
          {frame === null && <Header {...props} />}
          {getGlobalAlert()}
          <Outlet />
          {frame === null && <Footer />}
        </>
      }
    </>
  );
}

export default RouteWithoutSidebar;