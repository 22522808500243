import { Outlet } from 'react-router-dom';
import ModalManager from '../components/modals/ModalManager';

const ApplicationLayout = () => {
  return (
    <>
      <ModalManager />
      <Outlet />
    </>
  );
}

export default ApplicationLayout;