import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Col, Row, Card, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { JobAddress, JobSalary } from "../utils/JobUtils";
import { serialize_slate } from "../utils/SlateUtils";
import { addGlobalAlert } from "../actions/globalAlertActions";
import { openModal } from "../actions/modalActions";
import { createApplication } from "../api/applications.js";
import { can } from '../services/ability';
import NotAcceptingApplications from "./jobs/NotAcceptingApplications.js";
import ConditionalTooltip from "./ConditionalTooltip.js";
import { rewardIcon, rewardLabel } from '../utils/JobUtils';
import JobReward from "./jobs/JobReward.js";

function ViewJob({job, context, organizationId, businessId}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const frame = searchParams.get('frame');
  const authData = useSelector((state) => state.authData);
  const [benefitsToShow, setBenefitsToShow] = useState(3);
  const [eligibleToApply, setEligibleToApply] = useState(false)

  useEffect(()=> {
    if (job !== undefined && authData.loggedIn) {
      axios.get(`/api/v1/me/eligible_to_apply_to_job?job_id=${job.id}`)
      .then(
        (response) => {
          setEligibleToApply(response.data.result)
        }
      );
    } else {
      setEligibleToApply(false);
    }
  }, [job, authData])

  function handleShare(event) {
    dispatch(openModal('ShareJobModal', {job: job}));
  }

  function handleAtsRedirect(event) {
    if (eligibleToApply && !job.applications_paused) {
      createApplication({jobId: job.id, data: {"application": {}}})
        .then(
          (response) => {
            window.open(job.ats_link, '_blank');
            window.location.reload();
          },
          (error) => {
            dispatch(addGlobalAlert({message: "Problem applying to job. Are you signed in?", alertType: "danger"}));
          }
      );
    }
    else {
      window.open(job.ats_link, '_blank');
    }
  }

  function handleJobTitleClick(event) {
    if(context === "search") {
      const uri =
        businessId ? `/employers/${businessId}/jobs/${job.id}`
        : organizationId ? `/employers/${job.business?.id}/jobs/${job.id}`
        : `/jobs/${job.id}`;

      frame === null ? navigate(uri) : window.open(uri, '_blank');
    }
  }

  return (
    <div>
      <Card className="job-view p-3 mt-2 job">
        <Row>
          <Col xs={12} sm={6} md={7} lg={7}>
            <Card.Title className={context === "search" ? "job-title pointer" : "job-title"} onClick={e => {handleJobTitleClick(e)}}>{job?.title}</Card.Title>
          </Col>
          {frame === null ?
            <>
              {authData.loggedIn ?
                <Col className="job-links" xs={12} sm={6} md={5} lg={5}>
                  <Button className="standard-btn share-btn" onClick={e => handleShare(e)}><i className="fa fa-share-nodes me-2"></i>Share</Button>
                  {can(authData, 'create', 'Application') ? (
                    job.use_magnet_works_apply ? (
                      eligibleToApply ? (
                        <ConditionalTooltip condition={job.applications_paused} message={"Not currently accepting new applications."}>
                          <Link
                            to={
                              businessId ? `/employers/${businessId}/jobs/${job.id}/mwapply`
                              : organizationId ? `/employers/${job.business?.id}/jobs/${job.id}/mwapply`
                              : `/jobs/${job.id}/mwapply`
                            }
                            target={frame === null ? '_self' : '_blank'}
                          >
                            <Button className="standard-btn apply-btn" disabled={job.applications_paused ? "disabled" : null}>
                              <i className="fa fa-paper-plane me-2"></i>Instant Apply
                            </Button>
                          </Link>
                        </ConditionalTooltip>
                      ) : (
                        <OverlayTrigger placement="top" overlay={<Tooltip>You have already applied to this job.</Tooltip>}>
                          <div className="d-inline-block">
                            <Button className="standard-btn apply-btn" disabled="disabled"><i className="fa fa-paper-plane me-2"></i>Instant Apply</Button>
                          </div>
                        </OverlayTrigger>
                      )
                    ) : (
                      <ConditionalTooltip condition={job.applications_paused} message={"Not currently accepting new applications."}>
                        <span>
                          <Button className="standard-btn apply-btn" disabled={job.applications_paused ? "disabled" : null} onClick={(e) => handleAtsRedirect(e)}>
                            <i className="fa fa-paper-plane me-2"></i>Apply
                          </Button>
                        </span>
                      </ConditionalTooltip>
                    )
                  ) :
                    <OverlayTrigger placement="top" overlay={<Tooltip>Create a candidate profile to apply to jobs.</Tooltip>}>
                      <div className="d-inline-block">
                        <Button className="standard-btn apply-btn" disabled="disabled">Apply</Button>
                      </div>
                    </OverlayTrigger>
                  }
                </Col>
                :
                <Col className="job-links">
                  <Button className="standard-btn share-btn" onClick={() => (dispatch(openModal('SignUpModal')))}>Share</Button>
                  <Button className="standard-btn apply-btn" onClick={() => (dispatch(openModal('SignUpModal')))}>Apply</Button>
                </Col>
              }
            </>
            :
            <>
              <Col className="job-links" xs={12} sm={6} md={5} lg={5}>
                <Link
                  to={
                    businessId ? `/employers/${businessId}/jobs/${job.id}`
                    : organizationId ? `/employers/${job.business?.id}/jobs/${job.id}`
                    : `/jobs/${job.id}`
                  }
                  target={'_blank'}
                  >
                    <Button className="standard-btn share-btn"><i className="fa fa-share-nodes me-2"></i>More</Button>
                </Link>
              </Col>
            </>
          }
        </Row>
        <Card.Text className="d-flex job-subtitle">
          {job?.business?.logo && <img className="business-logo me-2" src={job.business.logo} alt="business-logo" />}
          {JobAddress({ job: job, includeFeedLink: businessId ? false : true})}
        </Card.Text>
        <Card.Subtitle className="text-muted">Posted {job?.posted_at} ago</Card.Subtitle>

        {job.applications_paused &&
          <Card.Text className="pt-1 mb-0">
            <NotAcceptingApplications />
          </Card.Text>
        }

        <Card.Subtitle className="mt-4">
          <i className='fa fa-briefcase me-3'></i>
          <span>{job?.job_type?.name} - {job?.job_experience_level?.name}</span>
        </Card.Subtitle>

        <Card.Subtitle className="mt-2">
          <i className='fa fa-money me-3'></i>
          <span>{JobSalary(job)}</span>
        </Card.Subtitle>

        <Card.Subtitle className="mt-2">
          <i className='fa fa-building me-3'></i>
          <span className="ms-1">{job?.job_industry?.name} - {job?.job_function?.name}</span>
        </Card.Subtitle>

        <Card.Subtitle className="mt-2">
          {rewardIcon(job.reward, 'me-3')}
          <span>Reward - {rewardLabel(job.reward)}</span>
        </Card.Subtitle>

        {job.benefits.length > 0 ?
          (<>
            <Card.Title className='job-inner-title mt-5'>Benefits</Card.Title>
            <Card.Body className="ps-0">
              <div className="d-flex flex-wrap gap-3 ps-0 mb-3">
                {job.benefits.sort((a, b) => a.position - b.position ).slice(0, benefitsToShow).map((benefit, index) => (<p key={index} className="job-bnefit-block text-muted">{benefit.name}</p>))}
              </div>
              {(job.benefits?.length > 3) ?
                (<a onClick={() => benefitsToShow === 3 ? setBenefitsToShow(job.benefits.length) : setBenefitsToShow(3)} className="acts-as-link">{benefitsToShow === 3 ? "show more" : "show less"}</a>)
                : ""
              }
            </Card.Body>
          </>)
          : ""
        }
        <Card.Title className='job-inner-title mt-3'>Full Job Description</Card.Title>
        <Card.Body className='p-0 job-inner-body'>
          {serialize_slate(job?.description, 'html')}
          <JobReward reward={job.reward} />
        </Card.Body>
      </Card>
    </div>
  );
}

export default ViewJob;